import React from "react";
import Layout from "../components/layout";
import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";
import StandardHeaderOneColumn from "../assets/elements/StandardHeaderOneColumn";
import Cta from "../partials/Cta";
import SEO from "../components/seo";

import LogoCloud from "../assets/elements/LogoCloud";
import ThreeColumnFeatureSection from "../assets/elements/ThreeColumnFeatureSection";
import TwoFeatureOverview from "../assets/elements/TwoFeatureOverview";
import { graphql, Script } from "gatsby";
import CaseStudyMSK from "../partials/CaseStudyMSK";
import { EncordGradientText } from "../utilities/DesignUtilities";
import CaseStudyStanford from "../partials/CaseStudyStanford";
import StandardSectionHeader from "../assets/elements/StandardSectionHeader";
import BlogCard from "../components/BlogCard";
import CustomLink from "../components/CustomLink";
import VideoComponent from "../components/VideoComponent";

export default function Radiology({ data, location }) {
  return (
    <Layout location={location}>
      <LeftColours />
      <RightColours />

      <StandardHeaderOneColumn
        title={
          <>
            Create Surgical Video AI Models 6x Faster{" "}
            <EncordGradientText text={"With Encord"} />
          </>
        }
        location={location}
        pageName="Radiology Page"
        heroGraphic={
          <>
            <VideoComponent loop autoPlay muted className="rounded-lg">
              <source
                src={
                  "https://prismic-io.s3.amazonaws.com/encord/8fc42972-01a0-4e65-b6ba-4a2d26b87f45_Surgery+-+Made+with+Clipchamp.webm"
                }
                type="video/webm"
                width="768"
                height="432"
              />
            </VideoComponent>
          </>
        }
        description={
          "Build better surgical intelligence models with Encord’s video labeling and clinical operations platform."
        }
      />

      <LogoCloud
        title={"Our Groundbreaking Healthcare Customers"}
        pb={10}
        pbMd={10}
        numImages={5}
        imageOne={
          <img
            src={
              "https://images.prismic.io/encord/1716e248-7485-43e9-9454-bb17670cd7d4_stanford-medicine-logo-vector.png?auto=compress,format"
            }
            alt={"Stanford Medicine Logo"}
            className={"h-24 w-32"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageTwo={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/8ed83228-e834-494f-84d4-06f0b698c5d6_logo_uhn.svg"
            }
            alt={"UHN logo"}
            className={"h-24 w-24"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageThree={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/ab584df2-0365-496d-a450-9df08c58fade_Memorial+Sloan+Kettering+Cancer+Center-1.svg"
            }
            alt={"MSK logo"}
            className={"h-20 w-44"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageFour={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/aa834409-1594-49f1-872e-d6b0ec856626_NHS_gstt.svg"
            }
            alt={"St Thomas logo"}
            className={"h-24 w-36 lg:w-40"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageFive={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/f3a7d750-8fcf-4a89-9212-59a427503a98_klc.svg"
            }
            alt={"KCL logo"}
            className={"h-24 w-24"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
      />

      {/* Three column feature section */}
      <ThreeColumnFeatureSection
        title={""}
        header={
          "Collaboratively Label and Manage Surgical Video Training Data with Encord"
        }
        description={
          <>
            <p>
              Encord is a medical-grade video annotation and clinical operations
              platform designed for surgical intelligence use cases, including
              surgical robotics and AI-assisted surgery.
            </p>
            <br />
            <p>
              Encord makes video labeling quicker and more efficient, reducing
              the hours your annotation team needs to spend labeling and
              reviewing images. And Encord’s advanced labeling protocols and
              data quality features ensure the best training data is being used
              to train your surgical intelligence models.
            </p>
            <br />
            <p>
              Develop your surgical AI models faster and more efficiently with
              Encord.
            </p>
          </>
        }
        features={[]}
      />

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeHeader={false}
        title={""}
        header={""}
        description={""}
        imageOne={
          <VideoComponent loop autoPlay muted className="rounded-lg">
            <source
              src={
                "https://prismic-io.s3.amazonaws.com/encord/859e266d-02d8-46e6-ab2e-1eb36629ce23_iStock-1391437423+%282%29.webm"
              }
              type="video/webm"
              width="768"
              height="432"
            />
          </VideoComponent>
        }
        imageTwo={
          <VideoComponent loop autoPlay muted className="rounded-lg">
            <source
              src={
                "https://prismic-io.s3.amazonaws.com/encord/a087e117-af5e-4fd9-abb4-c0ed8ad8c7d4_iStock-948440234.webm"
              }
              type="video/webm"
              width="768"
              height="432"
            />
          </VideoComponent>
        }
        imageThree={
          <img
            loading="lazy"
            src="https://encord.cdn.prismic.io/encord/e7877b2b-829c-45d6-98db-a826625a8d29_dicom-ontologies.svg"
            width={450}
            alt="Segmentation of brain in CT scan"
            className="rounded-lg"
          />
        }
        headerOne={"Efficient Medical Grade Image Labeling"}
        headerTwo={"A Single Platform for Surgical Video Annotation"}
        headerThree={"The Industry’s Most Powerful Labeling Protocols"}
        descriptionOne={
          <>
            <p>
              Encord supports rich labeling protocols within its surgical video
              annotation tool.
            </p>
            <br />
            <p>
              You can create complex labeling protocols covering as many feature
              types as you need to deliver the right video training data to your
              machine learning teams.
            </p>
          </>
        }
        descriptionTwo={
          <>
            <p>
              Encord offers multiple ways to annotate videos within a single
              platform, including bounding box, polygon, polyline, keypoint,
              segmentation and classification.
            </p>
            <br />
            <p>
              This means your annotators and reviewers only have to use a single
              piece of software to do their jobs. And your developers don’t have
              to try and maintain multiple in-house tools to cover each type of
              annotation task.
            </p>
          </>
        }
        descriptionThree={
          <>
            <p>
              Give your annotators a single platform for all their annotation
              types, including bounding box, polygon, polyline, keypoint,
              segmentation and classification.
            </p>
            <br />
            <p>
              And with a single platform, your developers are saved from
              maintaining multiple custom or open-source annotation tools.
            </p>
          </>
        }
      />

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeTopBorder
        includeTopPadding
        includeHeader={false}
        title={""}
        header={""}
        description={""}
        imageOne={
          <img
            loading="lazy"
            src="https://images.prismic.io/encord/fd7d159c-efdd-4908-9cf4-bd0c2ddef43b_Frame+481+%284%29.webp?auto=compress,format"
            width={600}
            alt="Expert review quality control for radiology"
          />
        }
        imageTwo={
          <img
            loading="lazy"
            src="https://images.prismic.io/encord/6749c655-6807-45ba-887b-247d490c466b_radiology-accuracy.png?auto=compress,format"
            width={550}
            alt="Encord improves the quality of your radiology training data"
          />
        }
        imageThree={
          <img
            loading="lazy"
            src="https://images.prismic.io/encord/8be76f23-8ebb-4c37-981a-a8badc35050d_radiology-approval.png?auto=compress,format"
            width={250}
            alt="Encord supports FDA clearance and CE marking processes"
          />
        }
        headerOne={"Efficient Clinical Data Operations"}
        headerTwo={"Create Better Surgical Video Training Data"}
        headerThree={"Supporting Your Regulatory Journey"}
        descriptionOne={
          <>
            <p>
              Encord has been designed to make it easier for you to meet your
              regulatory submissions, including FDA and CE approval.
            </p>
            <br />
            <p>
              In addition to being HIPAA and SOC-2 compliant, Encord’s data
              pipelines, auditable labels and QC and QA features make regulatory
              compliance much less of a headache.
            </p>
          </>
        }
        descriptionTwo={
          <>
            <p>
              Encord helps clinical operations teams identify the errors, biases
              and imbalances in the datasets they’ve created (even down to
              individual annotator performance).
            </p>
            <br />
            <p>
              This allows clinical operations leaders to provide better datasets
              to their machine learning teams and help reduce model time to
              production.
            </p>
          </>
        }
        descriptionThree={
          <>
            <p>
              Encord has been designed to make it easier for you to meet your
              regulatory requirements, including FDA submissions and CE
              approval.
            </p>
            <br />
            <p>
              As well as being{" "}
              <CustomLink
                className="font-medium text-gray-600 hover:text-gray-900"
                to={"/security/"}
              >
                HIPAA and SOC2
              </CustomLink>{" "}
              compliant, Encord’s data pipelines, fully auditable labels and
              quality control and assurance features make regulatory compliance
              much less of a headache.
            </p>
          </>
        }
      />

      <section className="pt-12 pb-12 border-t border-gray-200 bg-gradient-to-b from-gray-100 to-white">
        <div className="max-w-6xl px-4 mx-auto sm:px-6">
          <StandardSectionHeader
            title={"Customer Stories"}
            // header={"Enhance data quality and model visibility"}
            description={
              "Read how our customers are using Encord to accelerate the development of their healthcare machine learning models"
            }
          />
          <CaseStudyMSK />
          <br />
          <CaseStudyStanford includeCTALink />
          <br />
          <div className="flex-col md:flex md:justify-between">
            <p className="mx-auto text-3xl font-bold underline">
              Additional Resources
            </p>
            <section className="mt-[40px] grid grid-cols-1 gap-7 sm:grid-cols-2 lg:grid-cols-3">
              {data.allPrismicBlog &&
                !!data.allPrismicBlog?.edges.length &&
                data.allPrismicBlog?.edges
                  .filter(
                    (_blog) =>
                      _blog.node.uid !== data?.prismicBlog?.uid &&
                      _blog.node.tags.includes("Product")
                  )
                  .slice(0, 3)
                  .map((blog, index) => {
                    return (
                      <BlogCard
                        searchText={""}
                        location={location}
                        key={`blog_card_${index}`}
                        blog={blog}
                      />
                    );
                  })}
            </section>
          </div>
        </div>
      </section>

      <Cta
        pageName="Surgical AI Page"
        location={location}
        ctaText={
          "Enter your email address to request a free trial of Encord and see how it can accelerate your radiology AI development"
        }
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  {
    allPrismicBlog(sort: { first_publication_date: DESC }) {
      edges {
        node {
          uid
          data {
            author {
              document {
                ... on PrismicAuthor {
                  uid
                  data {
                    full_name {
                      text
                    }
                  }
                }
              }
            }
            content {
              html
            }
            body {
              ... on PrismicBlogDataBodyTable {
                id
                primary {
                  table_title {
                    text
                  }
                }
                items {
                  col1 {
                    text
                    html
                  }
                  col2 {
                    text
                    html
                  }
                  col3 {
                    text
                    html
                  }
                  col4 {
                    text
                    html
                  }
                }
              }
            }
            image {
              url
              gatsbyImageData(width: 400, placeholder: BLURRED)
            }
            sub_image {
              url
              gatsbyImageData(width: 400, placeholder: BLURRED)
            }
            title {
              text
            }
            read_time
          }
          first_publication_date
          tags
        }
      }
    }
  }
`;

export const Head = () => (
  <>
    <Script id="g2crowd-tracking-script" defer>
      {`(function (c, p, d, u, id, i) {
  id = ''; // Optional Custom ID for user in your system
  u = 'https://tracking.g2crowd.com/attribution_tracking/conversions/' + c + '.js?p=' + encodeURI(p) + '&e=' + id;
  i = document.createElement('script');
  i.type = 'application/javascript';
  i.async = true;
  i.src = u;
  d.getElementsByTagName('head')[0].appendChild(i);
}("1008306", document.location.href, document));`}
    </Script>
    <SEO
      title="Surgical Video Training Data Annotation Platform | Encord"
      description="We're helping leading healthcare institutions accelerate surgical AI development with collaborative video annotation tools."
    >
      <meta name="robots" content="noindex, nofollow" />
    </SEO>
  </>
);
